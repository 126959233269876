import React from 'react'
import styled from 'styled-components'

const Pill = ({ text }: { text: string }) => (
  <Frame>
    <Heading>{text}</Heading>
  </Frame>
)

export default Pill

const Frame = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  -webkit-background-clip: text;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(92.07deg, #bfa8a7 0.99%, #adbbd2 93.48%);
  background-size: cover;
  color: transparent !important;
`

const Heading = styled.h6`
  color: transparent;
  width: 100%;
  display: inline;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.4rem;
`
